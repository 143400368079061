import { useEffect, useContext, useRef } from 'react'
import firebase from 'firebase/app'
import 'firebase/messaging'

import Auth from 'store/auth'
import useAlert from 'store/common/useAlert'
import statisticContext from 'context/statisticsContext'

import { checkWindowExists } from 'utils/checkExists'
import { vapidKey, swPath, FirebaseConfig } from 'constants/firebase'
import { AlertType } from 'constants/alert'
import { LocalStorageKeys } from 'constants/storage'
import { WEEK } from 'constants/timeslot'
import { Events, SocketEvents } from 'constants/events'
import { IMessageEvent } from 'constants/types/web-push.types'

//TODO этот хук сейчас используется в двух местах, что-то тут не так
const usePushToken = () => {
  const { isNotAuthorized } = Auth.useAuth()
  const { setSingleAlert, deleteSingleAlert } = useAlert()
  const statisticSocket = useContext(statisticContext)
  const timer = useRef<any>(null)

  const isNotifications =
    checkWindowExists() && 'Notification' in window && firebase.messaging.isSupported()

  const closeNotification = () => {
    deleteSingleAlert({
      type: AlertType.ALLOW_NOTIF,
      id: AlertType.ALLOW_NOTIF,
    })
  }

  const handleDisagree = () => {
    localStorage.setItem(LocalStorageKeys.PUSH_WEEKLY_CHECK, String(new Date().getTime() + WEEK))
    closeNotification()
  }

  const sendToken = async () => {
    const messaging = firebase.messaging()
    const serviceWorkerRegistration = await navigator.serviceWorker.register(swPath as string)
    const registrationToken = await messaging.getToken({
      serviceWorkerRegistration,
      vapidKey,
    })
    if (registrationToken) {
      statisticSocket?.sendEvent(SocketEvents.WEBPUSH_REGISTRATION_TOKEN, { registrationToken })
      localStorage.setItem(LocalStorageKeys.PUSH_ACCEPTED, 'true')
      localStorage.removeItem(LocalStorageKeys.PUSH_WEEKLY_CHECK)
    } else {
      handleDisagree()
      console.log('No registration token available. Request permission to generate one.')
    }
  }

  const handleAgree = async () => {
    try {
      deleteSingleAlert({
        type: AlertType.ALLOW_NOTIF,
        id: AlertType.ALLOW_NOTIF,
      })
      await sendToken()
    } catch (err) {
      handleDisagree()
      console.log('Firebase err:', err)
    }
  }

  const handleGetToken = async () => {
    //получение первого ключа
    if (isNotAuthorized) return
    if (!statisticSocket.sendEvent) return
    if (!isNotifications) return
    if (Notification.permission === 'denied') return

    const isPushAccepted = localStorage.getItem(LocalStorageKeys.PUSH_ACCEPTED)
    if (isPushAccepted) return

    const prevPushDate = localStorage.getItem(LocalStorageKeys.PUSH_WEEKLY_CHECK)
    if (prevPushDate) {
      const leftTime = Number(prevPushDate) - new Date().getTime()
      if (leftTime > 0) {
        timer.current = setTimeout(() => {
          handleGetToken()
        }, leftTime)

        return () => clearTimeout(timer.current)
      }
    }

    try {
      if (!firebase.apps.length) firebase.initializeApp(FirebaseConfig as any)
      else firebase.app()

      setSingleAlert({
        type: AlertType.ALLOW_NOTIF,
        id: AlertType.ALLOW_NOTIF,
      })
    } catch (err) {
      console.log('Firebase err:', err)
    }
  }

  useEffect(() => {
    handleGetToken()

    if (isNotAuthorized) {
      closeNotification()
    }
  }, [statisticSocket?.sendEvent, isNotAuthorized])

  useEffect(() => {
    //повторная отправка ключа, на слаучай, если он обновлялся
    if (!statisticSocket.sendEvent) return
    if (!isNotifications) return
    const isPushAccepted = localStorage.getItem(LocalStorageKeys.PUSH_ACCEPTED)
    if (!isPushAccepted) return

    try {
      if (!firebase.apps.length) firebase.initializeApp(FirebaseConfig as any)
      else firebase.app()

      // firebase.analytics()
      sendToken()
    } catch (err) {
      console.log('Firebase err:', err)
    }
  }, [statisticSocket?.sendEvent])

  useEffect(() => {
    const handleEvent = (message: IMessageEvent) => {
      if (isNotAuthorized || !message?.data) return
      if (!statisticSocket.sendEvent) return

      const { platform, token } = message?.data
      console.log({ platform, token })
      if (token && platform)
        statisticSocket?.sendEvent(SocketEvents.WEBPUSH_REGISTRATION_TOKEN, {
          registrationToken: token,
          platform,
        })
    }
    // console.log('subscribe to message event')
    document.addEventListener(Events.MESSAGE, handleEvent as EventListener)

    return () => document.removeEventListener(Events.MESSAGE, handleEvent as EventListener)
  }, [statisticSocket?.sendEvent, isNotAuthorized])

  return {
    handleAgree,
    handleDisagree,
  }
}

export default usePushToken
